import {
    IonApp, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonIcon,
    IonLabel,
    IonRouterOutlet, IonRow,
    IonTabBar,
    IonTabButton,
    IonTabs,
    setupIonicReact
} from '@ionic/react';
import {IonSpinner} from '@ionic/react';
import {IonReactRouter} from "@ionic/react-router";
import {create, earth, list, location, logOutOutline, mapOutline, search} from "ionicons/icons";
import Tab1 from "./tabs/Tab1";
import Tab2 from "./tabs/Tab2";
import {Route} from "react-router-dom";
import React, {Suspense, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {App as CapApp} from "@capacitor/app";
import {Browser} from "@capacitor/browser";
import {Redirect} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {callbackUri} from "./auth.config";
import LoginButton from "./components/LoginButton";


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

// /* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
//
// /* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import {isMobile} from "react-device-detect";


setupIonicReact();

const App = () => {
    const {t, ready} = useTranslation();
    const {handleRedirectCallback, isLoading, isAuthenticated} = useAuth0();
    const {logout} = useAuth0();

    const doLogout = async () => {
        await logout({
            async openUrl(url) {
                await Browser.open({
                    url,
                    windowName: "_self",
                });
            },
            logoutParams: {
                returnTo: callbackUri
            }
        });
    };

    useEffect(() => {
        CapApp.addListener("appUrlOpen", async ({url}) => {
            if (url.startsWith(callbackUri)) {
                if (
                    url.includes("state") &&
                    (url.includes("code") || url.includes("error"))
                ) {
                    await handleRedirectCallback(url);
                }

                await Browser.close();
            }
        });
    }, [handleRedirectCallback]);


    if (isLoading) {
        return null;
    }

    if (isMobile) {
        return null;
    }

    if (!isAuthenticated) {
        return (
            <IonApp>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    backgroundColor: 'black'
                }}>
                    <IonCard>
                        <IonCardHeader style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>Bienvenido a BuscaPK</IonCardHeader>
                        <IonCardContent>
                            <IonCardContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <LoginButton/>
                            </IonCardContent>
                            <IonRow>
                                <p>Si encuentra algún problema por favor escriba a <a
                                    href="mailto:info@buscapk.es">info@buscapk.es</a></p></IonRow>
                        </IonCardContent>
                    </IonCard>
                </div>
            </IonApp>
        );
    }

    return (
        <IonApp>
            <IonReactRouter>
                <IonTabs>
                    <IonRouterOutlet>
                        <Route path="/map" component={Tab1} exact={true}/>
                        <Route path="/search" component={Tab2} exact={true}/>
                        <Route path="/" render={() => <Redirect to="/map"/>} exact={true}/>

                    </IonRouterOutlet>

                    <IonTabBar slot="bottom" style={{paddingBottom: '20px'}}>
                        <IonTabButton tab="map" href="/map">
                            <IonIcon icon={mapOutline}/>
                            <IonLabel>
                                {t('tabs.map')}
                            </IonLabel>
                        </IonTabButton>

                        <IonTabButton tab="search" href="/search">
                            <IonIcon icon={search}/>
                            <IonLabel>
                                {t('tabs.search')}
                            </IonLabel>
                        </IonTabButton>
                        <IonTabButton onclick={doLogout} id={"login-logout"}>
                            <IonIcon icon={logOutOutline}/>
                            <IonLabel>Salir</IonLabel>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
            </IonReactRouter>
        </IonApp>
    );
};

export default function WrappedApp() {
    return (
        <Suspense fallback={
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <IonSpinner/>
            </div>
        }>
            <App/>
        </Suspense>
    );
}