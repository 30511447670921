import { isPlatform } from "@ionic/react";

export const domain = "dev-phkr2uhglrm1ss0k.eu.auth0.com";
export const clientId = "qvXR54O19IYhu2CJRNCaL38AJjLLvdke";
const appId = "com.auth0.samples";

// Use `auth0Domain` in string interpolation below so that it doesn't
// get replaced by the quickstart auto-packager
export const auth0Domain = domain;
const iosOrAndroid = isPlatform('hybrid');

export const callbackUri = iosOrAndroid
  ? `${appId}://${auth0Domain}/capacitor/${appId}/callback`
  : 'https://cnp.buscapk.es';